<template>
  <div class="full-height">
    <div class="full-height-careteam-container pad-top-twenty pad-bottom-twenty">
      <ion-row class="full-height my-card">
        <ion-col class="full-height column-stretch-wrap full-height overflow-y-scroll">
          <div class="pad-top-twenty pad-lr-twenty column-stretch-header">
            <h6>Primary Care Provider</h6>
            <div class="pad-bottom-ten pad-top-ten">
              <div class="prime-ion-select">
                <ion-select
                  interface="popover"
                  placeholder="Select Patient's PCP"
                  mode="ios"
                  :value="currentPrimaryCareProvider"
                  @ionChange="changePCPStatus($event.target.value)"
                >
                  <ion-select-option
                    v-for="(member, key) in pcpsOnly"
                    :key="key"
                    :value="member.professionalId"
                    >{{
                      member.title
                        | formatDisplayName(member.firstName, member.lastName, member.suffix)
                    }}
                  </ion-select-option>
                </ion-select>
              </div>
            </div>
          </div>
          <div class="column-stretch-body pad-lr-twenty add-patient-card-scroll overflow-y-scroll">
            <div v-if="defaultCareTeam.length > 0">
              <div class="pad-bottom-ten">
                <h6>
                  Core Care Team
                </h6>
              </div>
              <ion-card
                v-for="(member, key) in defaultCareTeam"
                :key="key"
                class="prime-professional-default-card"
              >
                <div class="avatar" v-if="member.firstName && member.lastName">
                  {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
                </div>
                <div>
                  <div class="prime-professional-card-name">
                    {{
                      member.title
                        | formatDisplayName(member.firstName, member.lastName, member.suffix)
                    }}
                  </div>
                  <div class="text-muted text-small">
                    <span v-if="member.professionalRole">{{ member.professionalRole }} </span>
                  </div>
                </div>
              </ion-card>
            </div>
            <div v-if="activeCareTeam.length > 0">
              <div class="pad-bottom-ten pad-top-twenty">
                <h6>
                  Additional Care Team Members
                </h6>
              </div>
              <ion-card
                v-for="(member, key) in activeCareTeam"
                :key="key"
                class="prime-professional-default-card"
              >
                <ion-row class="full-width ion-align-items-center">
                  <ion-col size="auto">
                    <div class="avatar" v-if="member.firstName && member.lastName">
                      {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
                    </div>
                  </ion-col>
                  <ion-col>
                    <div>
                      <div class="prime-professional-card-name">
                        {{
                          member.title
                            | formatDisplayName(member.firstName, member.lastName, member.suffix)
                        }}
                      </div>
                      <div class="text-muted text-small">
                        <span v-if="member.professionalRole">{{ member.professionalRole }} </span>
                      </div>
                    </div>
                  </ion-col>
                  <ion-col size="auto">
                    <div class="close-button cursor-pointer" @click="confirmChangeCareTeam(member)">
                      &times;
                    </div>
                  </ion-col>
                </ion-row>
              </ion-card>
            </div>
            <div
              v-if="defaultCareTeam.length == 0 && activeCareTeam.length == 0"
              class="empty-container full-height text-muted"
            >
              <IconPersonAdd class="prime-tasks-empty-icon icon-watermark icon-grey" />
              <h6>There are no members in this Care Team</h6>
            </div>
          </div>
        </ion-col>
        <ion-col
          class="full-height column-stretch-wrap full-height overflow-y-scroll border-left bg-grey-two"
        >
          <div class="column-stretch-header pad-top-twenty pad-lr-twenty">
            <h6>Add Care Team Members</h6>
            <input
              v-if="availableCareTeam.length > 0"
              v-model="searchTerm"
              class="form-control"
              type="text"
              placeholder="Search for care team member..."
            />
          </div>
          <div v-if="filteredCareTeam.length > 0" class="column-stretch-body pad-top-twenty">
            <div class="pad-top-five add-patient-card-scroll pad-twenty">
              <ion-card
                v-for="(member, key) in filteredCareTeam"
                :key="key"
                class="prime-professional-card"
              >
                <ion-row class="full-width ion-align-items-center">
                  <ion-col size="auto">
                    <div class="avatar" v-if="member.firstName && member.lastName">
                      {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
                    </div>
                  </ion-col>
                  <ion-col>
                    <div>
                      <div class="prime-professional-card-name">
                        {{
                          member.title
                            | formatDisplayName(member.firstName, member.lastName, member.suffix)
                        }}
                      </div>
                      <div class="text-muted text-small">
                        <span v-if="member.professionalRole">{{ member.professionalRole }} </span>
                      </div>
                    </div>
                  </ion-col>
                  <ion-col size="auto">
                    <button
                      v-if="$can(I.MODIFY_PATIENT)"
                      @click="confirmChangeCareTeam(member)"
                      class="prime-button button-secondary button-block"
                    >
                      &plus; Add
                    </button>
                    <button
                      v-else
                      class="prime-button disabled button-secondary button-block"
                      v-tooltip.top-start="{
                        content: $orgMissingPermissions('The Modifying Patient feature')
                      }"
                    >
                      &plus; Add
                    </button>
                  </ion-col>
                </ion-row>
              </ion-card>
            </div>
          </div>
          <div v-else class="empty-container full-height text-muted">
            <IconPersonAdd class="prime-tasks-empty-icon icon-watermark icon-grey" />
            <h6>No available Care Team Members</h6>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";

export default {
  name: "CareTeam",
  components: {
    IconPersonAdd
  },
  props: {
    currentPatient: Object
  },
  data() {
    return {
      careTeam: Array,
      searchTerm: ""
    };
  },
  mounted() {
    this.careTeam = this.currentPatient.payload.careTeamMemberDetails;
  },
  methods: {
    confirmChangeCareTeam: function(member) {
      const alertHeaderTxt = member.careTeamMember ? "Confirm Removal" : "Confirm Add";
      const alertmessageTxt = member.careTeamMember
        ? "Are you sure you want to remove <strong>" +
          (member.lastName ? member.lastName : "") +
          (member.firstName ? ", " + member.firstName : "") +
          (member.suffix ? " " + member.suffix : "") +
          "</strong> from this care team?"
        : "Are you sure you want to add <strong>" +
          (member.lastName ? member.lastName : "") +
          (member.firstName ? ", " + member.firstName : "") +
          (member.suffix ? " " + member.suffix : "") +
          "</strong> to this care team?";
      const confirmBtnTxt = member.careTeamMember ? "Remove" : "Add";
      return this.$ionic.alertController
        .create({
          header: alertHeaderTxt,
          message: alertmessageTxt,
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: confirmBtnTxt,
              handler: () => {
                member.careTeamMember = !member.careTeamMember;
                this.sendPayload();
              }
            }
          ]
        })
        .then(a => a.present());
    },
    changePCPStatus: function(pcpId) {
      const t = this;

      if (this.currentPatient.payload.beneficiary.assignedProfessionalId == pcpId) {
        return;
      }

      // Remove old PCP from care team
      var oldPcpObject = this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return member.professionalId == t.currentPrimaryCareProvider;
      });

      oldPcpObject[0].careTeamMember = false;

      // Get member object for selected PCP by professionalId
      var memberObject = this.currentPatient.payload.careTeamMemberDetails.filter(
        obj => obj.professionalId == pcpId
      );
      // Convert array to object
      var member = memberObject[0];
      // reset all pcp status's to false
      for (var i = 0; i < this.careTeam.length; i++) {
        this.careTeam[i].assignedProfessional = false;
      }
      // assigned professional when true must also be added as a care team member

      member.assignedProfessional = false;
      member.careTeamMember = true;

      this.currentPatient.payload.beneficiary.assignedProfessionalId = pcpId;
      this.sendPayload();
    },
    sendPayload: async function() {
      this.loading = true;
      const method = "put";
      const path = document.config.patientSettings;
      const payload = this.currentPatient.payload;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          EventBus.$emit("patientPCPchanged");
          //this.currentPatient = result.data;
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "Patient Settings have been updated",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.loading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not save Patient Settings. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    }
  },
  computed: {
    currentPrimaryCareProvider() {
      this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return member.assignedProfessional == true;
      });
      return this.currentPatient.payload.beneficiary.assignedProfessionalId;
    },
    availableCareTeam: function() {
      const t = this;
      return this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return (
          member.professionalId != t.currentPrimaryCareProvider &&
          member.defaultCareTeamMember == false &&
          member.careTeamMember == false
        );
      });
    },
    filteredCareTeam: function() {
      if (this.searchTerm !== "") {
        return this.availableCareTeam.filter(member => {
          return (
            member.firstName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
            member.lastName.toLowerCase().match(this.searchTerm.toLowerCase())
          );
        });
      }

      return this.availableCareTeam;
    },
    activeCareTeam: function() {
      const t = this;
      return this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return (
          member.professionalId != t.currentPrimaryCareProvider &&
          member.defaultCareTeamMember == false &&
          member.careTeamMember == true
        );
      });
    },
    defaultCareTeam: function() {
      return this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return member.defaultCareTeamMember == true;
      });
    },
    pcpsOnly: function() {
      return this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return member.pcp;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.close-button {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 32px;
  margin-right: 10px;
}
ion-card {
  margin: 0px !important;
  padding: 10px;
}
ion-card.prime-professional-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-professional-card .checkbox {
  margin-left: 5px;
}
ion-card.prime-professional-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-professional-card
  .prime-professional-card-name
  ion-card.prime-professional-default-card
  .prime-professional-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-card
  .prime-professional-card-info
  ion-card.prime-professional-default-card
  .prime-professional-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-default-card {
  background: var(--ion-modal-secondary-background);
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-default-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}

.pcp-icon {
  width: 25px;
}
.pcp-icon.active {
  width: 35px;
}
.add-patient-card-scroll {
  height: 100%;
  overflow-y: scroll;
}
.icon-watermark {
  width: 140px;
  fill: #dadada;
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}
.border-left {
  border-left: 1px solid #e4e4e4;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
.my-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.2) !important;
  overflow: hidden;
}
.full-height-careteam-container {
  height: calc(100% - 100px);
}
</style>
